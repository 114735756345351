export default function doubleSlider() {
    let rangeMin = 100;
    const range = document.querySelector(".range-selected");
    const range_2 = document.querySelector(".range-selected-2");
    const rangeInput = document.querySelectorAll(".range-input input");
    const rangePrice = document.querySelectorAll(".range-price input");

    rangeInput.forEach((input) => {
        input.addEventListener("input", (e) => {
			let minRange = parseInt(rangeInput[0].value);
			let maxRange = parseInt(rangeInput[1].value);
			let minRange2 = parseInt(rangeInput[2].value);
			let maxRange2 = parseInt(rangeInput[3].value);
			
			if (maxRange - minRange < rangeMin) {     
				if (e.target.className === "min") {
					rangeInput[0].value = maxRange - rangeMin;
				} else {
					rangeInput[1].value = minRange + rangeMin;
				}
			} else {
			rangePrice[0].value = minRange;
			rangePrice[1].value = maxRange;

			range.style.left = (minRange / rangeInput[0].max) * 100 + "%";
			range.style.right = 100 - (maxRange / rangeInput[1].max) * 100 + "%";
			}

			if (maxRange2 - minRange2 < rangeMin) {     
				if (e.target.className === "min-2") {
					rangeInput[0].value = maxRange2 - rangeMin;
				} else {
					rangeInput[1].value = minRange2 + rangeMin;
				}
			} else {
			rangePrice[2].value = minRange2;
			rangePrice[3].value = maxRange2;

			range_2.style.left = (minRange2 / rangeInput[2].max) * 100 + "%";
			range_2.style.right = 100 - (maxRange2 / rangeInput[3].max) * 100 + "%";
			}
        });
    });
}