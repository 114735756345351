export default function formHome() {
    $(".menu-toggle-button, .menu-toggle-button.range").on("click", function () {
        event.preventDefault();
        let navigationMenu = $(this).next(".navigation-menu");
        navigationMenu.toggleClass("active");
    });

    let minAmountValueS = $("#minValueS")[0];
    let maxAmountValueS = $("#maxValueS")[0];

    $(".tab-header-item").on("click", function () {
        minAmountValueS = $(".tab-content-item.is-active #minValueS")[0];
        maxAmountValueS = $(".tab-content-item.is-active #maxValueS")[0];

        $(".range.s").html('Valor inicial - final <i class="fa fa-angle-down" aria-hidden="true"></i>');
    });

    $(".aplicars").on("click", function (e) {
        e.preventDefault();

        let combinedValue = `R$ ${minAmountValueS.value} - R$ ${maxAmountValueS.value}`;

        let menuToggleButton = $(".range.s");

        menuToggleButton.text(combinedValue);
        $(this).closest(".navigation-menu").removeClass("active");
    });

    
    $(document).on("click", function (event) {
        if (!$(event.target).closest(".navigation-menu").length &&
            !$(event.target).closest(".menu-filter").length) {
            $(".navigation-menu").removeClass("active");
        }
    });

    $(".menu-toggle-button").on("click", function (){
        $(".navigation-menu").removeClass("active"); 
        $(this).parent().find(".navigation-menu").addClass("active"); 
    });

    function getDefaultValue(element) {
        return parseInt($(element).data("default-values"));
    }
    
    function getMinValue(element) {
        return parseInt($(element).data("min-values"));
    }
    
    function getMaxValue(element) {
        return parseInt($(element).data("max-values"));
    }

    $(function () {
        $(".slider-range-mins").slider({
            range: "min",
            value: getDefaultValue(".slider-range-mins"),
            min: getMinValue(".slider-range-mins"),
            max: getMaxValue(".slider-range-mins"),
            slide: function (event, ui) {
                $(".minAmounts").text("R$ " + ui.value);
            },
        });

        $(".slider-range-maxs").slider({
            range: "min",
            value: getDefaultValue(".slider-range-maxs"),
            min: getMinValue(".slider-range-maxs"),
            max: getMaxValue(".slider-range-maxs"),
            slide: function (event, ui) {
                $(".maxAmounts").text("R$ " + ui.value);
            },
        });

        $(".minAmounts").text("R$ " + $(".slider-range-mins").slider("value"));
        $(".maxAmounts").text("R$ " + $(".slider-range-maxs").slider("value"));
    });

    $(document).ready(function () {
        $(".navigation-menu .menu-item").on("click", function (event) {
            event.preventDefault();
            let menuItemValue = $(this).text();
            let menuToggleButton = $(this).closest(".menu-filter__item").find(".menu-toggle-button");
            menuToggleButton.text(menuItemValue);
            $(this).closest(".navigation-menu").removeClass("active");
        });

        $(".aplicars").on("click", function (e) {
            e.preventDefault();
            let minAmountValueS = $(".minAmounts").html();
            let maxAmountValueS = $(".maxAmounts").html();

            let combinedValue = minAmountValueS + " - " + maxAmountValueS;

            let menuToggleButton = $(".range.s");

            menuToggleButton.text(combinedValue);
            $(this).closest(".navigation-menu").removeClass("active");
        });

        $(".tab-header-item").on("click", function () {
            $(".range.s").html('Valor inicial - final <i class="fa fa-angle-down" aria-hidden="true"></i>');
        });
    });
}
