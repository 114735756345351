export default function dinamicContainer() {
    if (document.querySelector(".right-container") || document.querySelector(".left-container")) {
        let rightContainer = document.querySelectorAll(".right-container");
        let leftContainer = document.querySelectorAll(".left-container");
        let defaultContainer = document.querySelector(".default-container");
        let containerStyle = defaultContainer.currentStyle || window.getComputedStyle(defaultContainer);

        if (rightContainer) {
            rightContainer.forEach((el) => {
                el.style["margin-right"] = containerStyle.marginRight;
                if((window.innerWidth || document.documentElement.clientWidth) > 1200){
                    el.style["padding-left"] = 0;
                }
            })
        }

        if (leftContainer) {
            leftContainer.forEach((el) => {
                el.style["margin-left"] = containerStyle.marginLeft;
                if((window.innerWidth || document.documentElement.clientWidth) > 1200){
                    el.style["padding-right"] = 0;
                }
            })
        }
    }
}
