export default function gallery(){
    if(document.querySelector("[data-fancybox='gallery']")){
        Fancybox.bind("[data-fancybox='gallery']", {
            Thumbs: {
                type: "classic",
            },
        });

        Fancybox.bind("[data-fancybox='gallery-mobile']", {
            Thumbs: {
                type: "classic",
            },
        });

        let openGallery = document.querySelector("#open-gallery");
        let firtsItem = document.querySelector("#gallery-first");
        
        openGallery.addEventListener("click", () => {
            firtsItem.click();
        });
    }
}