import { Swiper, Navigation, Pagination } from "swiper/dist/js/swiper.esm.js";

Swiper.use([Navigation, Pagination]);

export default () => {
    var swiper = new Swiper(".gallery-modal-slider", {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
    });

    // componente de banner padrão
    let bannerDefault = new Swiper(".banner__default", {
        watchOverflow: true,
        navigation: {
            nextEl: ".banner-button-next",
            prevEl: ".banner-button-prev",
        },
        pagination: {
            el: ".banner-pagination",
            type: "bullets",
            clickable: true,
        },
    });

    let bannerProperties = new Swiper(".properties-slide", {
        slidesPerView:1,
        grid: {
            columns: 2,
        },
        pagination: {
            el: ".properties-pagination",
            type: "bullets",
            clickable: true,
        },
    }); 

    let bannerImmobile = new Swiper(".banner-mobile", {
        watchOverflow: true,
        rewind: true,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        pagination: {
            el: ".banner-mobile-pagination",
            type: "bullets",
            clickable: true,
        },
    });

    let modalImmobile = new Swiper(".modal-slider-images", {
        watchOverflow: true,
        navigation: {
            nextEl: ".modal-slider-button-next",
            prevEl: ".modal-slider-button-prev",
        },
        pagination: {
            el: ".modal-slider-pagination",
            type: "bullets",
            clickable: true,
        },
    });

    let imSwiper = new Swiper(".imSwiper", {
        slidesPerView: "auto",
        spaceBetween: 10,
        pagination: {
        el: ".swiper-pagination",
        clickable: true,
        },
        rewind: true,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });
};
