export default function selectFilter() {
    applyFilter(document.querySelectorAll(".numbers__room"), "is-active");
    applyFilter(document.querySelectorAll(".numbers__bathroom"), "is-active");
    applyFilter(document.querySelectorAll(".numbers__spaces"), "is-active");
    applyFilter(document.querySelectorAll(".furniture__select"), "is-active");
}

function applyFilter(x, className) {
    x.forEach(el => {
        el.addEventListener("click", () => {
            x.forEach(e => e.classList.remove("is-active"));
            el.classList.add("is-active");
        });
    });
}